import * as moment from "moment";
import React from "react";
import { navigate } from "gatsby";
import { Post } from "../Data/Post";
import { Typography, Card, CardContent, Grid, CardActionArea, Collapse, CardActions, IconButton, CardHeader } from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import { Tags } from "./Tag";
import { ControlContext } from "./ControlContext";


export function PostPreview(props: { post: Post; })
{
    const { post } = props;
    const [ expanded, setExpanded ] = React.useState(false);
    const controlHeld = React.useContext(ControlContext);

    const showDetails = expanded || controlHeld;

    return <Grid key={post.id} item xs={true}>

        <Card>
            <CardActionArea onClick={() => setTimeout(() => navigate(post.slug), 280)}>

                <CardHeader
                    title={post.title}
                    subheader={moment(post.created).fromNow()}
                />

                <CardContent>

                </CardContent>

            </CardActionArea>

            <CardActions>

                <Tags tags={post.tags || []} />

                <IconButton
                    onClick={() => setExpanded(! expanded)}
                    aria-expanded={expanded}
                    aria-label="show more"
                    hidden={controlHeld}
                    style={{
                        visibility: showDetails ? "visible" : "hidden",
                        marginLeft: "auto",
                    }}
                >
                    <ExpandMoreIcon />
                </IconButton>

            </CardActions>

            <Collapse in={expanded}>
                <Typography variant="body2" className="language-json">
                    {JSON.stringify(post, null, 4)}
                </Typography>
            </Collapse>
        </Card>

    </Grid>;
}
