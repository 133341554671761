import React from "react";
import * as _ from "lodash";
import { Chip } from "@material-ui/core";
import { Settings, Code } from "@material-ui/icons";
import { Tag } from "../Data/Tag";


interface TagsProps
{
    tags: string[];
}

export function Tags(props: TagsProps)
{
    return <ul
        style={{
            listStyle: "none",
            padding: "0",
            margin: "0",
            marginLeft: "1em",
        }}
    >
        {
            props.tags.map((tag) => <li
                key={tag}
                style={{
                    display: "inline-block",
                    marginRight: "0.5em",
                }}
            >
                <TagChip name={tag} />
            </li>)
        }
    </ul>;
}

interface TagProps
{
    name: string;
}

export function TagChip(props: TagProps)
{
    return <Chip
        label={_.startCase(props.name)}
        icon={<TagIcon
            name={props.name}
            style={{
                fontSize: "2em",
            }}
        />}
        style={{
            padding: "0.7em",
            paddingTop: "1em",
            paddingBottom: "1em",
            fontSize: "0.6em",
        }}
    />;
}

interface TagIconProps
{
    name: string;
}

function TagIcon(props: TagIconProps)
{
    switch (props.name)
    {
        case Tag.BlogMeta:
            return <Settings { ...props } />;

        case Tag.Dotnet:
            return <Code { ...props } />;

        case Tag.AspNet:
            return <Code { ...props } />;

        default:
            return null;
    }
}
