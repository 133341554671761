import * as _ from "lodash";
import React from "react";
import WithFeed from "../Layouts/WithFeed";
import { graphql, StaticQuery } from "gatsby";
import { Post, PostResults } from "../Data/Post";
import { Grid } from "@material-ui/core";
import { PostPreview } from "../Components/PostPreview";
import { flow, map, flatmap } from "lodash/fp";


// eslint-disable-next-line import/no-default-export
export default function Home()
{
    return <WithFeed>
        <Grid
            container
            item
            direction="column"
            spacing={2}
            xs={true}
        >
            <StaticQuery<Post>
                query={query}
                render={(data: PostResults) => flow(
                    map("node"),
                    flatmap((post: Post) => <PostPreview
                        key={post.id}
                        post={post}
                    />)
                )(data.allPost.edges)}
            />
        </Grid>
    </WithFeed>;
}

const query = graphql`
    query IndexPosts {
        allPost(limit: 3, sort: {fields: created, order: DESC}) {
            edges {
                node {
                    id
                    internal {
                        mediaType
                        type
                        owner
                        ignoreType
                        fieldOwners
                        description
                        contentDigest
                    }
                    slug
                    title
                    created
                    updated
                    tags
                }
            }
        }
    }
`;
